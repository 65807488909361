<template lang="pug">
.info-study
  BaseHeaderComponent(
    :propTitle="propTitle",
    :propDates="selectedOption.text",
    :showForm="showForm",
    v-on:deleteThis="deleteThis",
    v-on:setViewer="setViewer"
  )

  .box-form(:class="{ expanded: showForm }")
    .grid-cols-mixed
      BaseInputText(label="Competencia:", v-model="form.section_title")
    .grid-cols-mixed
      baseInputSelect(
        :listOptions="options",
        v-model="selectedOption",
        position="bottom",
        label="Nivel:"
      )
</template>

<script>
import SectionCompetences from "@/store/models/competences.model";
import builderComponentsMixin from "../builder-components.mixin";
import BaseHeaderComponent from "./_base-header-component.vue";
export default {
  name: "competencesComponent",
  mixins: [builderComponentsMixin],
  components: {
    BaseHeaderComponent,
  },
  data: () => ({
    form: {
      section_title: "",
      section_competence_level: "",
      section_position: 0,
    },
    selectedOption: {
      text: "No mostrar",
      id: 0,
    },
    options: [
      {
        text: "No mostrar",
        id: 0,
      },
      {
        text: "Principiante",
        id: 1,
      },
      {
        text: "Cualificado/a",
        id: 2,
      },
      {
        text: "Experimentado/a",
        id: 3,
      },
      {
        text: "Experto/a",
        id: 4,
      },
    ],
  }),
  methods: {
    async updateItem() {
      await SectionCompetences.update({
        where: (item) => {
          return item.id == this.propItem.id;
        },
        data: {
          ...this.form,
        },
      });
    },
    async deleteThis() {
      await SectionCompetences.delete(this.propItem.id);
    },
  },
  watch: {
    selectedOption: {
      handler() {
        this.form.section_competence_level = this.selectedOption;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./components-style.scss";

.info-study {
  @apply border-b border-blue-100 py-4;
}
</style>
