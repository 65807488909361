<template lang="pug">
.info-study
  BaseHeaderComponent(
    :propTitle="propTitle",
    :propDates="form.section_reference_contact",
    :showForm="showForm",
    v-on:deleteThis="deleteThis",
    v-on:setViewer="setViewer"
  )
  .box-form(:class="{ expanded: showForm }")
    .grid-cols-mixed
      BaseInputText(
        label="Persona de contacto:",
        v-model="form.section_reference_contact"
      )
    .grid-cols-mixed
      BaseInputText(label="Empresa:", v-model="form.section_title")
    .grid-cols-mixed
      BaseInputText(label="Telefono", v-model="form.section_reference_phone")
    .grid-cols-mixed
      BaseInputText(
        label="Correo electronico",
        v-model="form.section_reference_email"
      )
</template>

<script>
import SectionReference from "@/store/models/references.model";
import builderComponentsMixin from "../builder-components.mixin";
import BaseHeaderComponent from "./_base-header-component.vue";

export default {
  name: "referencesComponent",
  mixins: [builderComponentsMixin],
  components: {
    BaseHeaderComponent,
  },
  data: () => ({
    showForm: false,
    form: {
      section_title: "",
      section_reference_contact: "",
      section_reference_phone: "",
      section_reference_email: "",
      section_position: 0,
    },
  }),
  methods: {
    async updateItem() {
      await SectionReference.update({
        where: (item) => {
          return item.id == this.propItem.id;
        },
        data: {
          ...this.form,
        },
      });
    },
    async deleteThis() {
      await SectionReference.delete(this.propItem.id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./components-style.scss";
.info-study {
  @apply border-b border-blue-100 py-4;
}
</style>