<template lang="pug">
.viewer
  .w-full.h-full.flex.flex-col.max-w-5xl
    .viewer-info(ref="viewInfo")

      .flex.justify-between.items-end
        XyzTransition(xyz="fade")
          .group-sync(v-if="isSync")
            BaseCustomIcon.icon.iconSync(nameIcon="mdiRefresh", :size="16")
            .txt Guardando
          .group-sync(v-else)
            BaseCustomIcon.icon.iconSuccess(nameIcon="mdiCheck", :size="16")
            .txt Guardado
        .button-back-editor(@click="$emit('closeViewer')")
          BaseCustomIcon(nameIcon="mdiChevronLeft")
          .txt Editor
        .button-download(@click="downloadPdf", :class="{ disabled: isSync }")
          BaseCustomIcon(nameIcon="mdiDownload")
          .txt Descargar

    .viewer-pdf(ref="viewRootPdf")
      .pdf(ref="viewPdf")
        CanvasPdf(:canvasDimensions="dimensionPdfComputed")
        XyzTransition(xyz="fade")
          .loader(v-if="$store.getters['DataCvViewerStore/isLoading']")
            .background
            .box-spinner
              BaseCustomIcon.animate-spin(nameIcon="mdiCogOutline", :size="24")
        .pagination
          .box-pagination
            BaseCustomIcon.icon(
              nameIcon="mdiChevronLeft",
              :size="28",
              :class="{ disabled: !enableButtonPrev }",
              @click="enableButtonPrev && $store.dispatch('DataCvViewerStore/prevPage')"
            )
            .pages.text-xs {{ `${pages.actualPage}-${pages.totalPages}` }}
            BaseCustomIcon.icon(
              nameIcon="mdiChevronRight",
              :size="28",
              :class="{ disabled: !enableButtonNext }",
              @click="enableButtonNext && $store.dispatch('DataCvViewerStore/nextPage')"
            )
    .viewer-options(ref="viewOptions")
      BottomOptions
</template>

<script>
import { UsersRepository } from "@/repository/users.repository";
import DownloadFile from "@/composables/download-file";

import { ref, onMounted, onUnmounted, computed } from "vue";
import { useStore } from "vuex";

import BottomOptions from "./bottom-options.vue";
import CanvasPdf from "./canvas-pdf.vue";
import { useRouter } from "vue-router";
export default {
  name: "viewerComponent",
  components: {
    BottomOptions,
    CanvasPdf,
  },
  setup() {
    const viewInfo = ref(null);
    const viewRootPdf = ref(null);
    const viewPdf = ref(null);
    const viewOptions = ref(null);
    const store = useStore();
    const router = useRouter();

    const dimensionPdf = ref({});

    const isSync = computed(() => {
      return store.getters["DataCvBuilderStore/getIsSync"];
    });

    const pages = computed(() => {
      return {
        actualPage: store.getters["DataCvViewerStore/cvPage"],
        totalPages: store.getters["DataCvViewerStore/cvNumPages"],
      };
    });

    const dimensionPdfComputed = computed(() => {
      return dimensionPdf.value;
    });

    onMounted(() => {
      resize(viewRootPdf);
      window.addEventListener("resize", resize);
    });

    onUnmounted(() => {
      window.removeEventListener("resize", resize);
    });

    const resize = () => {
      const hoja_h = viewRootPdf.value.clientHeight;
      const hoja_w = viewRootPdf.value.clientWidth;
      let calcularHeight = hoja_h;
      let calcularWith = (calcularHeight / 2.97) * 2.1;
      if (calcularWith > hoja_w) {
        calcularWith = hoja_w;
        calcularHeight = (calcularWith / 2.1) * 2.97;
      }
      setCanvas({
        width: calcularWith,
        height: calcularHeight,
      });
    };

    const setCanvas = (dimensions) => {
      const canvas_width =
        Math.round((dimensions.width + Number.EPSILON) * 1) / 1;
      const canvas_height =
        Math.round((dimensions.height + Number.EPSILON) * 1) / 1;
      viewPdf.value.style.height = `${canvas_height}px`;
      viewPdf.value.style.width = `${canvas_width}px`;
      viewInfo.value.style.width = `${canvas_width}px`;
      viewOptions.value.style.width = `${canvas_width}px`;
      dimensionPdf.value = {
        canvas_width,
        canvas_height,
      };
    };

    const enableButtonPrev = computed(() => {
      return store.getters["DataCvViewerStore/cvPage"] > 1;
    });
    const enableButtonNext = computed(() => {
      return (
        store.getters["DataCvViewerStore/cvPage"] <
        store.getters["DataCvViewerStore/cvNumPages"]
      );
    });

    const downloadPdf = () => {
      if (isSync.value) return;
      const curriculum_id = store.getters["DataCvBuilderStore/getCurriculumId"];
      if (curriculum_id) {
        UsersRepository.downloadCurriculum(curriculum_id)
          .then((curriculum) => {
            const { downloadFile } = DownloadFile();
            downloadFile(curriculum.pdf_base64)
              .then(() => {
                store.dispatch("UserStore/loadMeData");
              })
              .catch(function (error) { });
          })
          .catch(({ response }) => {
            if (
              response.data.message &&
              response.data.message == "disabled-downloads"
            ) {
              router.push({
                name: "builder/payment/with-id",
                params: { curriculum_id: curriculum_id },
              });
            }
          });
      } else {
        router.push({
          name: "builder/payment",
        });
      }
    };

    return {
      viewRootPdf,
      viewPdf,
      viewInfo,
      viewOptions,
      isSync,
      dimensionPdfComputed,
      pages,
      enableButtonPrev,
      enableButtonNext,
      downloadPdf,
    };
  },
};
</script>

<style lang="scss" scoped>
.button-back-editor {
  @apply flex items-center bg-blue-400 text-white font-bold rounded-md pl-3 pr-6 py-1 text-lg cursor-pointer 2lg:hidden;
}
.viewer {
  height: calc(100vh - 56px);
  top: 0;
  margin-top: 56px;
  @apply fixed px-4 py-4 w-full right-0 top-0 bg-blue-50 2lg:w-1/2 2lg:-right-0.5 2lg:px-8;

  .viewer-info {
    @apply w-full flex-shrink pb-4 self-center;
    .button-download {
      @apply flex items-center bg-green-500 text-white font-bold rounded-md pl-3 pr-6 py-1 text-lg ml-auto cursor-pointer transition-opacity;
      .txt {
        @apply pl-2;
      }

      &.disabled {
        opacity: 0.7;
      }
    }

    .group-sync {
      @apply items-center text-sm hidden 2lg:flex;

      &.xyz-in,
      &.xyz-out {
        @apply absolute;
      }

      .txt {
        @apply pl-1 text-gray-600 font-light;
      }

      .icon {
        padding: 3px;
        @apply h-6 w-6 rounded-full border bg-white;
      }

      .iconSuccess {
        @apply text-green-500 border-green-500;
      }

      .iconSync {
        @apply text-blue-500 border-blue-500 animate-spin;
      }
    }
  }

  .viewer-pdf {
    @apply w-full flex-grow flex justify-center;

    .pdf {
      @apply bg-white rounded-md overflow-hidden shadow-xl relative;

      .pagination {
        @apply w-full flex items-center justify-center absolute bottom-8;
        .box-pagination {
          @apply bg-gray-400 flex items-center rounded-2xl text-white;

          .pages {
            @apply mx-2;
          }

          .icon {
            @apply rounded-full h-6 w-6 cursor-pointer;

            &.disabled {
              @apply opacity-50 cursor-default;
            }

            &:hover:not(.disabled) {
              @apply bg-gray-500;
            }
          }
        }
      }
    }
  }

  .viewer-options {
    @apply bg-white w-full flex-shrink h-16 mt-4 rounded-md border border-blue-200 self-center;
  }
}

.loader {
  @apply absolute w-full h-full top-0 flex items-center justify-center;
  .background {
    @apply absolute w-full h-full bg-white top-0 opacity-40;
  }

  .box-spinner {
    @apply bg-blue-600 p-4 rounded-md shadow-xl text-white z-10;
  }
}
</style>
