<template lang="pug">
.info-study
  BaseHeaderComponent(
    :propTitle="propTitle",
    :propDates="propDates",
    :showForm="showForm",
    v-on:deleteThis="deleteThis",
    v-on:setViewer="setViewer"
  )

  .box-form(:class="{ expanded: showForm }")
    .grid-cols-mixed
      BaseInputText(label="Curso:", v-model="form.section_title")
    .grid-cols-mixed
      BaseInputText(
        label="Institución/Centro:",
        v-model="form.section_university"
      )
    .grid-cols-mixed
      BaseInputText(label="Fecha de inicio:", v-model="form.section_date_from")
    .grid-cols-mixed
      BaseInputText(
        label="Fecha de finalización:",
        v-model="form.section_date_until"
      )
    .grid-cols-full
      FormsBaseTextArea(
        :content="form.section_description",
        v-on:update="updateDescription"
      )
</template>

<script>
import SectionCourses from "@/store/models/courses.model";
import builderComponentsMixin from "../builder-components.mixin";
import BaseHeaderComponent from "./_base-header-component.vue";
export default {
  name: "coursesComponent",
  mixins: [builderComponentsMixin],
  components: {
    BaseHeaderComponent,
  },
  data: () => ({
    form: {
      section_title: "",
      section_university: "",
      section_date_actual: false,
      section_date_from: "",
      section_date_until: "",
      section_description: "",
      section_position: 0,
    },
  }),
  methods: {
    async updateItem() {
      await SectionCourses.update({
        where: (item) => {
          return item.id == this.propItem.id;
        },
        data: {
          ...this.form,
        },
      });
    },
    async deleteThis() {
      await SectionCourses.delete(this.propItem.id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./components-style.scss";

.info-study {
  @apply border-b border-blue-100 py-4;
}
</style>
