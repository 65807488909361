<template lang='pug'>
.wrapper-editor
  .form-editor
    XyzTransition(appear, xyz="left-1 fade delay-1")
      .pre-wrapper(v-if="!$store.getters['DataCvBuilderStore/getIsLoading']")
        PersonalDataComponent
        draggable(
          v-if="listSections2.length > 0",
          v-model="listSections2",
          v-bind="dragOptions",
          tag="transition-group",
          handle=".move-icon",
          item-key="_id"
        )
          template(#item="{ element, index }")
            CustomsSectionComponentWrapper(
              v-if="/custom/gm.exec(element._id)",
              :componentName="element._id",
              :position="index"
            )
            FormItemDragableWrapper(
              :componentName="element._id",
              :position="index",
              v-else
            )
        OtherComponentsComponent.mb-8
  ViewerComponent(v-if="viewDrawerComponent" v-on:closeViewer="forceShowViewer = false")

ButtonSwitcherViewMobile(v-if="!viewDrawerComponent" v-on:openViewer="forceShowViewer = true")
</template>
<script>
import PersonalDataComponent from "@/components/builder/components/personal-data-component.vue";
import FormItemDragableWrapper from "@/components/builder/form-item-dragable-wrapper.vue";
import CustomsSectionComponentWrapper from "@/components/builder/components/customs-section-component-wrapper.vue";
import OtherComponentsComponent from "@/components/builder/components/other-components-component.vue";
import ViewerComponent from "@/components/www/builder/viewer/viewer-component.vue";
import draggable from "vuedraggable";
import ButtonSwitcherViewMobile from '@/components/builder/button-switcher-view-mobile.vue'

export default {
  components: {
    PersonalDataComponent,
    draggable,
    FormItemDragableWrapper,
    OtherComponentsComponent,
    CustomsSectionComponentWrapper,
    ViewerComponent,
    ButtonSwitcherViewMobile
  },
  inject: ["$ObserserFormSync"],

  data: () => ({
    firstIntentDecline: 0,
    dragOptions: {
      animation: 300,
      group: "master",
      disbled: false,
      swapThreshold: 5,
      fallbackOnBody: true,
      fallbackClass: "master-dragged",
      ghostClass: "master-ghost",
      chosenClass: "master-chosen", // Class name for the chosen item
      dragClass: "master-drag", // Class name for the dragging item
    },
    mql: window.matchMedia('(max-width: 1124px)'),
    isMobile: false,
    forceShowViewer: false
  }),
  async created() {
    console.log(this.$store.getters["DataCvBuilderStore/getCurriculumId"]);
    if (!this.$store.getters["DataCvBuilderStore/getCurriculumId"]) {
      await this.$store.dispatch("DataCvBuilderStore/createCV");
    }
    this.mql.addEventListener('change', this.setComponentIsMobile);
    this.setComponentIsMobile(this.mql)
  },
  async beforeUnmount() {
    this.$store.dispatch("entities/deleteAll");
    this.mql.removeEventListener("change", this.setComponentIsMobile);
  },
  computed: {
    listSections2: {
      get() {
        const orderItems =
          this.$store.getters["OrderCvComponentsStore/getOrder"];
        return orderItems.map((item) => {
          return {
            type: item,
            _id: item,
          };
        });
      },
      set(value) {
        const NewlistSections = value.map((l) => l.type);
        this.$store.dispatch(
          "OrderCvComponentsStore/changeOrderComponents",
          NewlistSections
        );
      },
    },
    viewDrawerComponent() {
      if (this.isMobile && this.forceShowViewer) {
        return true;
      } else if (!this.isMobile && !this.$store.getters['DataCvBuilderStore/getIsLoading']) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    listSections2: {
      deep: true,
      handler() {
        this.$ObserserFormSync();
      },
    },
  },
  methods: {
    setComponentIsMobile(event) {
      this.isMobile = event.matches;
    }
  }
};
</script>
<style lang="scss" scoped>
.wrapper-editor {
  @apply grid grid-cols-1 min-h-full relative w-full 2lg:grid-cols-2;

  .form-editor {
    @apply flex bg-white flex-grow flex-shrink-0 justify-center shadow-sm 2lg:justify-end;
  }
}
.pre-wrapper {
  @apply flex flex-col w-full max-w-4xl 2lg:max-w-5xl;
}
</style>