<template lang="pug">
.info-study
  BaseHeaderComponent(
    :propTitle="propTitle",
    propDates="",
    :showForm="showForm",
    v-on:deleteThis="deleteThis",
    v-on:setViewer="setViewer"
  )
  .box-form(:class="{ expanded: showForm }")
    .col-span-2
      BaseInputText(label="Título:", v-model="form.section_title")
    .col-span-2
      FormsBaseTextArea(
        :content="form.section_description",
        v-on:update="updateDescription"
      )
</template>

<script>
import SectionCustomItems from "@/store/models/custom.item.model";
import builderComponentsMixin from "../builder-components.mixin";
import BaseHeaderComponent from "./_base-header-component.vue";

export default {
  name: "customItemComponent",
  mixins: [builderComponentsMixin],
  components: {
    BaseHeaderComponent,
  },
  data: () => ({
    showForm: false,
    form: {
      section_title: "",
      section_description: "",
      section_position: 0,
    },
  }),
  methods: {
    async updateItem() {
      await SectionCustomItems.update({
        where: (item) => {
          return item._id == this.propItem._id;
        },
        data: {
          ...this.form,
        },
      });
    },
    async deleteThis() {
      await SectionCustomItems.delete(this.propItem.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.info-study {
  @apply border-b border-blue-100 py-4;
}
.box-form {
  max-height: 0;
  opacity: 0;
  transition: all 0.25s ease-in-out;
  @apply grid grid-cols-2 gap-4 overflow-hidden;

  &.expanded {
    max-height: 1000px;
    opacity: 1;
    @apply px-2 pt-4 overflow-visible;
  }
}
</style>