export default {
  emits: ['setItemSelected'],
  props: {
    item: {
      type: Object,
      default: null
    },
    position: {
      type: Number,
      default: 0
    },
    itemIdSelected: {
      type: String,
      default: null
    }
  },
  data: () => ({
    showForm: false
  }),
  created() {
    this.form = { ...this.item };
  },
  computed: {
    propItem() {
      return this.item;
    },
    getitemIdSelected() {
      return this.itemIdSelected;
    },
    propTitle() {
      return this.item.section_title || 'Not defined';
    },
    propDates() {
      return `${this.item.section_date_from || '--'} - ${this.item.section_date_until || ' --'}`;
    }
  },
  watch: {
    form: {
      deep: true,
      inmediate: true,
      handler() {
        this.updateItem();
      }
    },
    position: {
      inmediate: true,
      async handler() {
        this.form.section_position = this.position;
      }
    },
    getitemIdSelected: {
      inmediate: true,
      deep: true,
      async handler() {
        if (this.getitemIdSelected == this.propItem._id) this.showForm = true;
        else this.showForm = false;
      }
    }
  },
  methods: {
    setViewer() {
      if (!this.showForm) this.$emit('setItemSelected', this.propItem._id);
      else this.$emit('setItemSelected', null);
    },
    updateDescription(value) {
      this.form.section_description = value.replaceAll('"', "'");
    }
  }
};
